import * as Sentry from '@sentry/nuxt'

import { $isFetchError } from './helpers/fetch'

const { ENV_NAME, SENTRY_DSN, SENTRY_ENABLED, SENTRY_RELEASE_ID } =
  useRuntimeConfig().public

Sentry.init({
  // If set up, you can use your runtime config here
  // dsn: useRuntimeConfig().public.sentry.dsn,
  dsn: SENTRY_DSN,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: ENV_NAME === 'prod' ? 0.1 : 1.0,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    Sentry.piniaIntegration(usePinia()),
    Sentry.vueIntegration({
      tracingOptions: {
        trackComponents: true,
        timeout: 500, // milliseconds
      },
    }),
  ],

  environment: ENV_NAME ?? 'prod',
  release: SENTRY_RELEASE_ID,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  enabled: SENTRY_ENABLED === 'true',
  ignoreErrors: ['FetchError'],
  beforeSend(event, { originalException }) {
    if ($isFetchError(originalException)) return null
    return event
  },
})
